import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import CoccLogo from "../../images/cocc-ftr-logo-circle.svg"

import "../../styles/image-left.scss" // include sass styles

export default function ImageLeft({ data, slug }) {
  return (
    <div className="image-left-wrapper">
      <div className="image-wrapper">
        <GatsbyImage image={data?.image?.localFile.childImageSharp.gatsbyImageData} alt={data?.image?.altText} />
      </div>
      <div className="content-wrapper">
        <div className="content">
          {(slug === 'airplane-degree-program') ? (
            <img src={CoccLogo} className="cocc-logo" alt="COCC Logo" width="100" height="96" />
          ) : ''}

          <h2 className="title">{data?.title}</h2>
          <h4 className="subtitle">{data?.subtitle}</h4>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
          {(data?.button)
            ?
            <a href={data?.button?.url} className="button" target={data?.button?.target}>{data?.button?.title}</a>
            :
            ''
          }
        </div>
      </div>
    </div>
  )
}
