import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../components/layout"
import Banner from "../components/blocks/Banner"
import CTABoxes from "../components/blocks/CTABoxes";
import TextContent from "../components/blocks/TextContent";
import ContentWithSidebar from "../components/blocks/ContentWithSidebar";
import ImageLeft from "../components/blocks/ImageLeft";
import Testimonials from "../components/blocks/Testimonials";
import TogglesWithImage from "../components/blocks/TogglesWithImage";
import ComparisonTables from "../components/blocks/ComparisonTables";
import Logos from "../components/blocks/Logos";
import FeaturedSlider from "../components/blocks/FeaturedSlider";
import TwoColumns from "../components/blocks/TwoColumns";
import ImageRight from "../components/blocks/ImageRight";
import Instructors from "../components/blocks/Instructors";
import BoxedTwoColumns from "../components/blocks/BoxedTwoColumns";
import TimeComparison from "../components/blocks/TimeComparison";
import FinancingTable from "../components/blocks/FinancingTable";
import HeaderSimple from "../components/blocks/HeaderSimple";
import Fleet from "../components/blocks/Fleet";
import Steps from "../components/blocks/Steps";
import DividedText from "../components/blocks/DividedText";
import BoxedSlider from "../components/blocks/BoxedSlider";
import Positions from "../components/blocks/Positions";
import TwoColumnWithBox from "../components/blocks/TwoColumnWithBox";
import Toggles from "../components/blocks/toggles";
import TwoColumnSideImage from "../components/blocks/TwoColumnSideImage";
import MeetTeamMember from "../components/blocks/MeetTeamMember";
import TwoColumnDivider from "../components/blocks/TwoColumnDivider";
import FlagBoxes from "../components/blocks/FlagBoxes";
import TestimonialsGrid from "../components/blocks/TestimonialsGrid";
import ContactForm from "../components/blocks/ContactForm";
import Journey from "../components/blocks/Journey";

export default function Page({ data, location }) {
  const page = data?.wpPage
  const blocks = page?.blocks?.layouts

  return (
    <Fragment>
      <Seo post={page} />
      <Layout location={location}>
        {blocks && blocks.length && blocks.map(block => {
          if (block && block.fieldGroupName) {
            const name = block?.fieldGroupName.replace('Page_Blocks_Layouts_', '') // get block name
            // console.log(name)

            if (name === 'Banner') {
              return (
                <Banner data={block} slug={page?.slug} location={location} />
              )
            }

            if (name === 'CtaBoxes') {
              return (
                <CTABoxes data={block} />
              )
            }

            if (name === 'TextContent') {
              return (
                <TextContent data={block} />
              )
            }

            if (name === 'ContentWithSidebar') {
              return (
                <ContentWithSidebar data={block} />
              )
            }

            if (name === 'ImageLeft') {
              return (
                <ImageLeft data={block} slug={page?.slug} />
              )
            }

            if (name === 'Testimonials') {
              return (
                <Testimonials data={block} />
              )
            }

            if (name === 'TogglesWithImage') {
              return (
                <TogglesWithImage data={block} />
              )
            }

            if (name === 'ComparisonTables') {
              return (
                <ComparisonTables data={block} />
              )
            }

            if (name === 'Logos') {
              return (
                <Logos data={block} />
              )
            }

            if (name === 'FeaturedSlider') {
              return (
                <FeaturedSlider data={block} />
              )
            }

            if (name === 'TwoColumns') {
              return (
                <TwoColumns data={block} slug={page?.slug} />
              )
            }

            if (name === 'ImageRight') {
              return (
                <ImageRight data={block} />
              )
            }

            if (name === 'Instructors') {
              return (
                <Instructors data={block} />
              )
            }

            if (name === 'BoxedTwoColumns') {
              return (
                <BoxedTwoColumns data={block} />
              )
            }

            if (name === 'TimeComparison') {
              return (
                <TimeComparison data={block} />
              )
            }

            if (name === 'FinancingTable') {
              return (
                <FinancingTable data={block} />
              )
            }

            if (name === 'HeaderSimple') {
              return (
                <HeaderSimple data={block} />
              )
            }

            if (name === 'Fleet') {
              return (
                <Fleet data={block} />
              )
            }

            if (name === 'Steps') {
              return (
                <Steps data={block} />
              )
            }

            if (name === 'DividedText') {
              return (
                <DividedText data={block} />
              )
            }

            if (name === 'BoxedSlider') {
              return (
                <BoxedSlider data={block} />
              )
            }

            if (name === 'Positions') {
              return (
                <Positions data={block} />
              )
            }

            if (name === 'TwoColumnWithBox') {
              return (
                <TwoColumnWithBox data={block} />
              )
            }

            if (name === 'Toggles') {
              return (
                <Toggles data={block} />
              )
            }

            if (name === 'TwoColumnSideImage') {
              return (
                <TwoColumnSideImage data={block} />
              )
            }

            if (name === 'MeetTeamMember') {
              return (
                <MeetTeamMember data={block} />
              )
            }

            if (name === 'TwoColumnDivider') {
              return (
                <TwoColumnDivider data={block} />
              )
            }

            if (name === 'FlagBoxes') {
              return (
                <FlagBoxes data={block} />
              )
            }

            if (name === 'TestimonialsGrid') {
              return (
                <TestimonialsGrid data={block} />
              )
            }

            if (name === 'ContactForm') {
              return (
                <ContactForm data={block} />
              )
            }

            if (name === 'Journey') {
              return (
                <Journey data={block} />
              )
            }

            // ...etc for each block
          }

          return true
        })}

        {!blocks ? (
          <div className="page-wrapper">
            <h1>{page?.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: page?.content }} />
          </div>
        ) : null}

      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      seo {
       title
       metaDesc
       focuskw
       metaKeywords
       opengraphTitle
       opengraphDescription
       opengraphImage {
         altText
         sourceUrl
         srcSet
       }
       twitterTitle
       twitterDescription
       twitterImage {
         altText
         sourceUrl
         srcSet
       }
       canonical
       cornerstone
       schema {
         articleType
         pageType
         raw
       }
      }
      slug
      title
      content
       blocks {
        layouts {
          ... on WpPage_Blocks_Layouts_Banner {
            content
            title
            video
            directVideoLink
            subtitle
            buttons {
              button {
                url
                title
                target
              }
            }
            background {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
            mobileBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
            fieldGroupName
          }
          ... on WpPage_Blocks_Layouts_CtaBoxes {
            fieldGroupName
            boxes {
              title
              link {
                target
                title
                url
              }
              content
              background {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TextContent {
            backgroundColor
            darkTheme
            content
            fieldGroupName
            subtitle
            title
            buttons {
              button {
                target
                title
                url
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
              altText
            }
            pdf {
              guid
            }
          }
          ... on WpPage_Blocks_Layouts_ContentWithSidebar {
            fieldGroupName
            sidebar {
              videoContent {
                video
                title
                content
                button {
                  target
                  url
                  title
                }
              }
              textContent {
                button {
                  target
                  url
                  title
                }
                content
                title
              }
            }
            main {
              button {
                target
                title
                url
              }
              content
              title
              gallery {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              slider {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_ImageLeft {
            content
            fieldGroupName
            title
            subtitle
            button {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Testimonials {
            fieldGroupName
            testimonials {
              ... on WpTestimonial {
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                title
                content
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    publicUrl
                    sourceUrl
                  }
                }
                testimonials {
                  instagramUrl {
                    target
                    title
                    url
                  }
                  video
                  position
                }
              }
            }
            category {
              slug
              name
            }
          }
          ... on WpPage_Blocks_Layouts_TogglesWithImage {
            fieldGroupName
            toggles {
              button {
                url
                title
                target
              }
              content
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Logos {
            fieldGroupName
            title
            logos {
              link {
                target
                title
                url
              }
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_FeaturedSlider {
            fieldGroupName
            slides {
              content
              position
              subtitle
              title
              video
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TwoColumns {
            darkTheme
            fieldGroupName
            col1 {
              button {
                target
                title
                url
              }
              content
              subtitle
              title
              video
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            col2 {
              button {
                target
                title
                url
              }
              content
              title
              video
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_BoxedTwoColumns {
            anchorLink
            fieldGroupName
            col2 {
              content
              video
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            col1 {
              button {
                target
                title
                url
              }
              title
              subtitle
              content
              logo {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TimeComparison {
            content
            fieldGroupName
            title
            subtitle
            rows {
              from
              time1
              time2
              to
              type1
              type2
            }
            planeIcon {
              altText
              localFile {
                publicURL
              }
            }
            carIcon {
              altText
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Blocks_Layouts_ImageRight {
            anchorLink
            content
            fieldGroupName
            video
            title
            subtitle
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            button {
              target
              title
              url
            }
          }
          ... on WpPage_Blocks_Layouts_FinancingTable {
            fieldGroupName
            content
            explanation
            title
            table {
              headings {
                col
              }
              section {
                title
                row {
                  check
                  col
                }
              }
            }
            image {
              altText
              localFile {
                publicURL
              }
            }
            buttons {
              button {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Fleet {
            fieldGroupName
            subtitle
            title
            background {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
            video
            box {
              button {
                target
                url
                title
              }
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_Instructors {
            fieldGroupName
            title
            instructors {
              ... on WpInstructor {
                id
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    publicUrl
                    sourceUrl
                    altText
                  }
                }
                categories {
                  nodes {
                    name
                  }
                }
                title
                content
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Steps {
            fieldGroupName
            title
            steps {
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_ComparisonTables {
            fieldGroupName
            subtitle
            title
            columns {
              heading
              cols {
                col
              }
              button {
                target
                title
                url
              }
            }
          }
          ... on WpPage_Blocks_Layouts_DividedText {
            fieldGroupName
            title
            dividerBoxes {
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_HeaderSimple {
            fieldGroupName
            title
            background {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            mobileBackground {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_BoxedSlider {
            content
            fieldGroupName
            title
            slides {
              content
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              title
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              link {
                title
                target
                url
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TwoColumnSideImage {
            content
            fieldGroupName
            button {
              target
              title
              url
            }
            imageAlignment
            title
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_TwoColumnWithBox {
            content
            fieldGroupName
            title
            box {
              content
            }
            button {
              target
              title
              url
            }
          }
          ... on WpPage_Blocks_Layouts_Toggles {
            fieldGroupName
            toggles {
              content
              title
            }
          }
          ... on WpPage_Blocks_Layouts_TwoColumnDivider {
            fieldGroupName
            title
            dividers {
              title
            }
            video
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_FlagBoxes {
            fieldGroupName
            col1 {
              boxes {
                content
                title
              }
            }
            col2 {
              boxes {
                content
                title
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Positions {
            fieldGroupName
            title
            positions {
              link {
                target
                title
                url
              }
            }
          }
          ... on WpPage_Blocks_Layouts_MeetTeamMember {
            content
            fieldGroupName
            title
            subtitle
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_ContactForm {
            fieldGroupName
            gfFormId
            title
            contact {
              content
              map
              map2
              title
            }
          }
          ... on WpPage_Blocks_Layouts_TestimonialsGrid {
            category {
              slug
              name
            }
            fieldGroupName
            subtitle
            title
            testimonials {
              ... on WpTestimonial {
                content
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                title
                slug
                featuredImage {
                  node {
                    altText
                    localFile {
                      publicURL
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Blocks_Layouts_Journey {
            fieldGroupName
            optionsTitle
            form {
              gfFormId
              icon {
                altText
                localFile {
                  publicURL
                }
              }
              title
            }
            sections {
              content
              button {
                url
                target
                title
              }
              redirect {
                url
                target
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              options {
                title
                content
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                button {
                  url
                  target
                  title
                }
                redirect {
                  url
                  target
                }
                subOptions {
                  content
                  title
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  button {
                    url
                    target
                    title
                  }
                  redirect {
                    url
                    target
                  }
                  endOptions {
                    content
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    title
                    learnMore {
                      content
                      buttons {
                        button {
                          url
                          title
                          target
                        }
                      }
                      icon {
                        altText
                        localFile {
                          publicURL
                        }
                      }
                      title
                    }
                    thankYou {
                      content
                      title
                    }
                    formContent
                    button {
                      url
                      target
                      title
                    }
                    redirect {
                      url
                      target
                    }
                  }
                }
              }
              title
            }
            subtitle
            title
          }
        }
      }
    }
  }
`

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }
